@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .conversation-row-grid {
        height: calc(100vh - 65px);
        grid-template-rows: auto 1fr auto;
    }
}

